import { useState } from "react";
import { Display } from "react-7-segment-display";
import { Button, TextField } from "@mui/material";
const NotReadyComponent = ({ time_left, emergency_code, note, handleUseEmergencyCode, error }) => {
  const [eCode, setECode] = useState('');
  const [years, days, hours, minutes, seconds] = time_left.split(':');

  return (
    <div>
      <div className="text-red-500 font-bold text-xl text-center mb-4">It's Not Time Yet</div>
      {note && <div className="text-center mb-4">{note}</div>}
      <div className="flex items-center justify-center space-x-2 bg-black text-white p-4 flex-wrap mb-4">
        <div className="text-center"><span className="text-red">{years}</span><p>Years</p></div>
        <div className="text-center"><span className="text-red">{days}</span><p>Days</p></div>
        <div className="text-center"><span className="text-red">{hours}</span><p>Hours</p></div>
        <div className="text-center"><span className="text-red">{minutes}</span><p>Minutes</p></div>
        <div className="text-center"><span className="text-red">{seconds}</span><p>Seconds</p></div>
      </div>
      {emergency_code && (
      <div className="text-center">
        <TextField label="Emergency Code" variant="outlined" fullWidth style={{ marginBottom: "8px"}} onChange={(e) => setECode(e.target.value)} />
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <Button variant="contained" color="primary" onClick={() => handleUseEmergencyCode(eCode)}>
          Unlock With Emergency Code
        </Button>
      </div>
      )}
    </div>
  );
}
 
export default NotReadyComponent;
