import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../images/logo.webp';
const HeaderComponent = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  const menuItems = [
    { text: 'Home', link: '/' },
    { text: 'Create Capsule', link: '/create-capsule' },
    { text: 'Open Capsule', link: '/open-capsule' },
    { text: 'FAQ', link: '/faq' },
    { text: 'Contact', link: '/contact' },
  ];
  const drawer = (
    <div className="w-64 h-screen" role="presentation" style={{ backgroundColor: '#a4951e' }}>
      <div className="flex justify-end p-4">
        <IconButton onClick={toggleDrawer(false)} className="text-white">
          <CloseIcon className="text-white" />
        </IconButton>
      </div>
      <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
        {menuItems.map((item) => (
          <ListItem button key={item.text} component={Link} to={item.link}>
            <ListItemText 
              primary={item.text} 
              primaryTypographyProps={{ 
                className: 'text-white font-semibold' 
              }} 
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
  return (
    <>
      <AppBar position="static" className="shadow-lg w-full" style={{ backgroundColor: '#a4951e' }} >
        <Toolbar className="flex items-center justify-between max-w-6xl mx-auto w-full" style={{ padding: '0.5rem 1rem' }}>
          <div className="flex items-center">
            <img src={Logo} alt="Moment Stash Logo" className="h-12 mr-4" />
            <Typography variant="h4" className="text-white" style={{ fontFamily: "'Bebas Neue', sans-serif" }} >
              Moment Stash
            </Typography>
          </div>
          <div className="hidden md:flex space-x-6">
            {menuItems.map((item) => (
              <Link to={item.link} key={item.text} className="text-white font-semibold">
                {item.text}
              </Link>
            ))}
          </div>
          <div className="flex md:hidden">
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} >
        {drawer}
      </Drawer>
    </>
  );
}
export default HeaderComponent;
