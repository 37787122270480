import React, { useRef, useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import { useLocation } from 'react-router-dom';
import { Container, Card, CardHeader, CardContent, Button } from "@mui/material";
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import QRCode from 'react-qr-code';
const CapsuleCreatedPage = () => {
  const location = useLocation();
  const data = location.state?.cdata;
  const contentRef = useRef(null);
  useEffect(() => {
      if (contentRef.current) {
        htmlToImage.toPng(contentRef.current)
        .then((dataUrl) => {
            console.log('Base64 image URL:', dataUrl);
            try {
              const response = fetch('https://momentstash.com/api/email-created', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ capsule_id: data?.capsule_id, image: dataUrl }) });
            } catch (error) {
              console.error('Failed to send email', error);
            }
        })
        .catch((error) => {
            console.error('Failed to generate base64 image', error);
        });
      }
  }, [contentRef,  data?.capsule_id]);
  const handleDownload = () => {
    if (contentRef.current) {
      htmlToImage.toPng(contentRef.current)
        .then((dataUrl) => {
          download(dataUrl, 'capsule-id.png');
        })
        .catch((error) => {
          console.error('Failed to download image', error);
        });
    }
  };
  const handlePrint = () => {
    if (contentRef.current) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(contentRef.current.outerHTML);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  };
  return (
    <div className="flex flex-col min-h-screen text-white">
      <HeaderComponent />
      <main className="flex-grow py-6">
        <Container maxWidth="lg">
          <Card className="shadow-lg rounded-lg bg-white bg-opacity-80">
            <CardHeader
              title="Capsule Created"
              titleTypographyProps={{ variant: "h5" }}
              className="bg-gradient-to-r from-teal-500 to-blue-500 text-white"
            />
            <CardContent className="w-full flex flex-col items-center">
              <div ref={contentRef} className="flex flex-col items-center justify-center p-10 border-4 border-gray-800 bg-white" >
                <h1 className="text-3xl font-bold">Moment Stash Capsule Created!</h1>
                {/* QR Code */}
                <div className="mt-6 mb-4 p-4 bg-gray-100 rounded-lg">
                  <QRCode value={data?.capsule_id} />
                </div>
                <p className="mt-4 text-lg">Your Capsule ID is</p>
                <h2 className="mt-2 text-2xl font-semibold">{data?.capsule_id}</h2>
                <p className="mt-4 text-lg">Save this ID as you will need it to open your capsule.</p>
              </div>
              <div className="mt-6 flex space-x-4">
                <Button variant="contained" color="primary" onClick={handleDownload}>
                  Download
                </Button>
                <Button variant="contained" color="secondary" onClick={handlePrint}>
                  Print
                </Button>
              </div>
            </CardContent>
          </Card>
        </Container>
      </main>
      <FooterComponent />
    </div>
  );
}
export default CapsuleCreatedPage;
