import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import CreateCapsulePage from "./pages/CreateCapsulePage";
import CapsuleCreatedPage from "./pages/CapsuleCreatedPage";
import OpenCapsule from "./pages/OpenCapsule";
import CreatingCapsule from "./pages/CreatingCapsule";
import PrivacyPage from "./pages/PrivacyPage";
import TOSPage from "./pages/TOSPage";
import ContactPage from "./pages/ContactPage";
import FaqPage from "./pages/FaqPage";

import TestPage from "./pages/TestPage";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/create-capsule" element={<CreateCapsulePage />} />
				<Route path="/creating-capsule" element={<CreatingCapsule />} />
				<Route path="/capsule-created" element={<CapsuleCreatedPage />} />
				<Route path="/open-capsule" element={<OpenCapsule />} />
				<Route path="/test" element={<TestPage />} />
				<Route path="/privacy" element={<PrivacyPage />} />
				<Route path="/tos" element={<TOSPage />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/faq" element={<FaqPage />} />
			</Routes>
		</Router>
	);
}

export default App;
