import React from 'react';
import { Container, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Example icon, replace with actual icons as needed

const useCases = [
  {
    title: "Create a message for your future self",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
  {
    title: "Preserve family memories for future generations",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
  {
    title: "Save a message for a loved one for a special occasion",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
  {
    title: "Document a significant life event",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
  {
    title: "Keep a record of personal achievements or milestones",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
  {
    title: "Store digital mementos of an unforgettable trip",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
  {
    title: "Leave a message for your child to open on their birthday",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
  {
    title: "Save a message for a future important event",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
  {
    title: "Send one to your significant other!",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
  {
    title: "Keep a digital diary of your personal growth",
    icon: <AccessTimeIcon />, // Replace with appropriate icon
  },
];

const HeroReasons = () => {
  return (
    <Container className="my-8 bg-white rounded-lg shadow-xl border-2 border-msgold">
      <Typography variant="h5" gutterBottom className="text-center mb-8 text-gray-800 p-2">
        Top Reasons to Use Our Digital Time Capsule
      </Typography>
      <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {useCases.map((useCase, index) => (
          <ListItem key={index} sx={{ width: '100%', maxWidth: '600px' }}>
            <ListItemIcon>
              {useCase.icon}
            </ListItemIcon>
            <ListItemText
              primary={`${index + 1}. ${useCase.title}`}
              primaryTypographyProps={{ variant: 'h6', className: 'text-gray-800' }}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default HeroReasons;
