import React from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import HeroImage from "../images/hero.webp";

const HomeHero = () => {
  return (
    <Container maxWidth="xl" className="bg-white mx-auto rounded-lg shadow-xl border-2 border-msgold overflow-hidden my-8">
      <Box py={4} textAlign="center">
        <Typography variant="h3" component="h1" className="text-gray-800 font-bold">
          Welcome to Moment Stash!
        </Typography>
      </Box>
      <Grid container spacing={4} alignItems="center" justifyContent="center" className="p-6 md:p-12">
        <Grid item xs={12} md={6} className="flex justify-center mb-6 md:mb-0">
          <img
            src={HeroImage}
            alt="Family holding clock"
            className="rounded-lg shadow-lg w-full h-auto object-cover"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="p"
            className="text-lg leading-relaxed text-gray-700 text-center md:text-left"
          >
            Capture the essence of your most treasured moments and preserve them forever with Moment Stash. Our digital time capsules let you safeguard the memories that matter most—whether it’s a heartfelt message, a milestone achievement, or a cherished photograph. Create a lasting legacy that future generations can unlock, reliving the emotions, stories, and experiences that define your life. With Moment Stash, time is no longer a barrier; it’s a bridge to tomorrow. Begin your journey of preserving precious moments today.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeHero;
