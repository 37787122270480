import React from 'react';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const FaqPage = () => {
  const faqs = [
    {
      question: "What is Moment Stash?",
      answer: "Welcome to Moment Stash – your personal vault for life's most cherished moments. Our platform allows you to create and preserve digital time capsules, ensuring your memories, messages, and milestones are securely stored and shared with the future. Whether it's a heartfelt message for a loved one, a snapshot of a life-changing event, or a wish for the years to come, Moment Stash helps you freeze time and revisit these special moments whenever you want. Start building your legacy today and ensure that your most meaningful experiences are never forgotten."
    },
    {
      question: "How do I create a time capsule?",
      answer: "Creating a time capsule with Moment Stash is incredibly simple—no account is required! Just fill out a straightforward form, select any options or extras you’d like, make the payment, and boom! Your digital time capsule is created, ready to be preserved for the future. It’s as easy as that. Whether you’re capturing a special memory, leaving a message for a loved one, or marking a significant milestone, Moment Stash makes it effortless to preserve your most important moments for years to come."
    },
    {
      "question": "Is my data secure?",
      "answer": "Yes, we take data security very seriously. Your time capsules are stored securely, and only you have access to them unless you choose to share them. We utilize the latest technology and industry-leading cloud services to safeguard your data. Our infrastructure includes advanced encryption, data isolation, and continuous monitoring to ensure that your information remains secure and accessible only to you. With these measures in place, you can trust that your moments are protected every step of the way."
    },
    {
      "question": "How long will my time capsule be preserved?",
      "answer": "Your time capsule is designed to stand the test of time. We are committed to ensuring that your cherished memories remain safe and accessible for years to come. By leveraging cutting-edge cloud technology, robust data storage solutions, and continuous system monitoring, we guarantee the longevity and durability of your data. Rest assured, your moments will be preserved with the highest level of care and reliability."
    },
    
    
    {
      question: "Is there a limit to how many time capsules I can create?",
      answer: "No, you can create as many time capsules as you like!"
    },
    {
      question: "Can I open my capsule early?",
      answer: "Yes, if you opted to include an emergeny open code when you created it. However, capsules without an emergency open code can only be opened on the date you specified."
    },
    {
      question: "How much does it cost?",
      answer: "Affordable and Transparent Pricing – Moment Stash offers a variety of plans to suit your needs, with prices starting at just $2! You can customize your time capsule with extras or add-ons, which are clearly labeled as you add them. Want to include files? No problem—simply add them for just $1 each. Check out our pricing page for all the details and find the perfect plan for preserving your precious moments without breaking the bank."
    },
  ];
  return (
    <>
      <HeaderComponent />
    <Container maxWidth="md" className="py-12">
      <Box className="bg-white p-8 shadow-lg rounded-lg">
        <Typography variant="h4" gutterBottom className="font-bold">
          Frequently Asked Questions
        </Typography>
        {faqs.map((faq, index) => (
          <Accordion key={index} className="mt-4">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bg-gray-100">
              <Typography variant="h6" className="font-semibold">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
    <FooterComponent />
    </>
  );
};
export default FaqPage;
