import React, { useState } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import { Container, Card, CardHeader, CardContent, TextField, Button, Box } from "@mui/material";
import NotReadyComponent from '../components/NotReadyComponent';
import CapsuleViewComponent from '../components/CapsuleViewComponent';
const OpenCapsule = () => {
  const [capsuleId, setCapsuleId] = useState('');
  const [capsulePIN, setCapsulePIN] = useState('');
  const [capState, setCapState] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);
  const [note, setNote] = useState(null);
  const [hasEmergencyCode, setHasEmergencyCode] = useState(false);
  const [capsuleData, setCapsuleData] = useState(null);
  const [error, setError] = useState(null);
  const handleUseEmergencyCode = async (eCode) => { 
    setError(null);
    try {
      const response = await fetch("https://momentstash.com/api/use-ecode", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ capsule_id: capsuleId, emergency_code: eCode })
      });
      if(!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      if(data.error) {
        setError(data.message);
        return;
      }
      const parsedData = JSON.parse(data.message);
      setCapsuleData(parsedData);
      setCapState(2);
    } catch (error) {
      setError("Error using emergency code: " + error);
    }
  };
  const handlePINChange = (event) => {
    setCapsulePIN(event.target.value);
  };
  const handleInputChange = (event) => {
    setCapsuleId(event.target.value);
  };
  const handleButtonClick = async () => {
    try {
      setError(null);
      // Implement button click functionality here
      const response = await fetch("https://momentstash.com/api/get-capsule", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ capsule_id: capsuleId, capsule_pin: capsulePIN })
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json(); // Assuming the response is in JSON format
      if(data.error) {
        setError(data.message);
        return;
      }
      const parsedData = JSON.parse(data.message);
      if (parsedData.time_left) {
        setTimeLeft(parsedData.time_left); // Note the correct property name
        setNote(parsedData.note);
        if(parsedData.emergency_code) {
          setHasEmergencyCode(true);
        }
        setCapState(1);
      } else if (parsedData.expired) {
        setCapsuleData(parsedData);
        setCapState(2);
      }
      console.log(parsedData); // Log parsed data for debugging
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors, such as network issues
    }
  };
  return (
    <div className="flex flex-col min-h-screen text-white">
      <HeaderComponent />
      <main className="flex-grow py-6">
        <Container maxWidth="lg">
          <Card className="shadow-lg rounded-lg bg-white bg-opacity-80">
            <CardHeader title="Open Capsule" titleTypographyProps={{ variant: "h5" }} className="bg-gradient-to-r from-teal-500 to-blue-500 text-white" />
            <CardContent className="w-full flex flex-col items-center">
              {capState === 0 && (
              <>
                <Box mb={2} width="100%" maxWidth="sm">
                  <TextField label="Capsule ID" variant="outlined" fullWidth value={capsuleId} onChange={handleInputChange} />
                  <TextField label="Capsule PIN (Optional)" variant="outlined" fullWidth style={{ marginTop: "8px"}} onChange={handlePINChange} />
                </Box>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                <Button variant="contained" color="primary" onClick={handleButtonClick} >
                  Open Capsule
                </Button>
              </>
            )}
            {capState === 1 && <NotReadyComponent time_left={timeLeft} emergency_code={hasEmergencyCode} note={note} handleUseEmergencyCode={handleUseEmergencyCode} error={error} />}
            {capState === 2 && <CapsuleViewComponent capsuleData={capsuleData} />}
            </CardContent>
          </Card>
        </Container>
      </main>
      <FooterComponent />
    </div>
  );
}
export default OpenCapsule;
