import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';

const PrivacyPage = () => {
  return (
    <>
      <HeaderComponent />
    <Container maxWidth="md" className="py-12">
      <Box className="bg-white p-8 shadow-lg rounded-lg">
        <Typography variant="h4" gutterBottom className="font-bold">
          Privacy Policy
        </Typography>
        <Typography variant="body1" className="my-4">
          Welcome to Moment Stash. Your privacy is critically important to us. This Privacy Policy explains how we collect, use, and share your personal information when you use our website.
        </Typography>
        
        <Typography variant="h6" className="font-semibold mt-6">
          1. Information We Collect
        </Typography>
        <Typography variant="body1" className="my-4">
          We collect information you provide directly to us, such as when you create an account, create a time capsule, or communicate with us. This may include your name, email address, payment information, and any content you add to your time capsules.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" className="my-4">
          We use your information to provide, operate, and maintain our services, to improve and personalize your experience, and to communicate with you.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          3. Sharing of Information
        </Typography>
        <Typography variant="body1" className="my-4">
          We do not share your personal information with third parties except to comply with the law, protect our rights, or fulfill the purpose for which you provide it.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          4. Security
        </Typography>
        <Typography variant="body1" className="my-4">
          We take reasonable measures to help protect your information from loss, theft, misuse, and unauthorized access.
        </Typography>


        <Typography variant="h6" className="font-semibold mt-6">
          5. Changes to This Policy
        </Typography>
        <Typography variant="body1" className="my-4">
          We may update this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, provide additional notice.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          6. Contact Us
        </Typography>
        <Typography variant="body1" className="my-4">
          If you have any questions about this Privacy Policy, please contact us.
        </Typography>
      </Box>
    </Container>
    <FooterComponent />
    </>
  );
};

export default PrivacyPage;
