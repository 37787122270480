import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HomeCall = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/create-capsule');
  };

  return (
    <Container className="my-8 bg-white rounded-lg shadow-xl border-2 border-msgold p-8 text-center">
      <Typography variant="h4" gutterBottom className="mb-4 text-gray-800">
        Start Your Time Capsule Journey
      </Typography>
      <Typography variant="body1" className="mb-8 text-gray-600">
        Whether you want to create a time capsule for yourself or send a special message to someone else, start preserving your memories today.<br/>
      </Typography>
      <Box display="flex" justifyContent="center" gap={2} className="my-4">
        <Button variant="contained" color="primary" size="large" onClick={handleClick}>
          Create for Myself
        </Button>
        <Button variant="outlined" color="primary" size="large" onClick={handleClick}>
          Send to Someone Else
        </Button>
      </Box>
    </Container>
  );
};

export default HomeCall;
