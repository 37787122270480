import React from 'react';
import { Container, Typography, Button, Card, CardContent, Grid } from '@mui/material';
import SecureIcon from '../images/secure_icon.png';
import GuaranteeIcon from '../images/guarantee_icon.png';


const TestPage = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="bg-cover bg-center text-black py-20 text-center" >
      <Container>
        <Typography variant="h2" className="font-bold mb-6">Preserve Your Moments for the Future</Typography>
        <Typography variant="h5" className="mb-10">
          Create digital time capsules to safeguard your most cherished memories.
        </Typography>
        <Button variant="contained" color="primary" size="large">Get Started Now</Button>
      </Container>
    </section>

      {/* Why Choose Us */}
      <section className="py-16 bg-gray-100">
      <Container>
        <Typography variant="h4" className="text-center font-semibold mb-10">
          Why Choose Moment Stash?
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="shadow-lg">
              <CardContent>
                <div className="flex items-center mb-4">
                  {/* Placeholder image for Secure and Private */}
                  <img src={SecureIcon} alt="Secure and Private" className="w-8 h-8 mr-2" />
                  <Typography variant="h5" className="font-semibold">
                    Secure and Private
                  </Typography>
                </div>
                <Typography>
                  Your data is protected with advanced encryption, ensuring your moments remain private and secure.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className="shadow-lg">
              <CardContent>
                <div className="flex items-center mb-4">
                  {/* Placeholder image for Guaranteed Longevity */}
                  <img src={GuaranteeIcon} alt="Guaranteed Longevity" className="w-8 h-8 mr-2" />
                  <Typography variant="h5" className="font-semibold">
                    Guaranteed Longevity
                  </Typography>
                </div>
                <Typography>
                  Your digital time capsules are built to last, ensuring they are accessible whenever you need them.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>

      {/* Use Cases Section */}
      <section className="bg-[#a9a269] py-16">
        <Container>
          <Typography variant="h4" className="text-center font-semibold mb-10">Ideas for Your Digital Time Capsules</Typography>
          <Grid container spacing={4}>
            {[
              "Create a message for your future self",
              "Preserve family memories for future generations",
              "Save a message for a loved one for a special occasion",
              "Document a significant life event",
              "Keep a record of personal achievements or milestones",
              "Store digital mementos of an unforgettable trip",
              "Leave a message for your child to open on their birthday",
              "Save a message for a future important event",
              "Send one to your significant other!",
              "Keep a digital diary of your personal growth"
            ].map((useCase, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Card className="shadow-lg h-full">
                  <CardContent>
                    <Typography variant="h6" className="font-semibold mb-2">{useCase}</Typography>
                    <Typography>
                      Make your memories last forever by storing them in a digital time capsule.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </section>

      {/* Call to Action */}
      <section className="py-16 text-center bg-[#a4951e]">
        <Container>
          <Typography variant="h5" className="mb-6">Start Stashing Your Moments Today!</Typography>
          <Button variant="contained" color="secondary" size="large">Create Your First Time Capsule</Button>
        </Container>
      </section>
    </div>
  );
};

export default TestPage;
