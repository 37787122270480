import { useState, useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import { Container, Card, CardHeader, CardContent, TextField, Switch, Button, Divider, } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import PaymentModal from "../modals/PaymentModal";
import { useNavigate } from "react-router-dom";

const CreateCapsulePage = () => {
  const navigate = useNavigate();
	const [modalOpen, setModalOpen] = useState(false);
	const [clientSecret, setClientSecret] = useState(null);
	const [preCapsuleId, setPreCapsuleId] = useState(null);
	const handleOpen = () => setModalOpen(true);
	const handleClose = () => setModalOpen(false);
	const [files, setFiles] = useState([]);
	const [error, setError] = useState("");
	const [sendToSomeone, setSendToSomeone] = useState(false);
	const [usePin, setUsePin] = useState(false);
	const [useEmergencyUnlock, setUseEmergencyUnlock] = useState(false);
	const [showNote, setShowNote] = useState(false);
	const [price, setPrice] = useState(2.0);
	const [formData, setFormData] = useState({ from_name: "", from_email: "", to_name: "", to_email: "", message: "", openstamp: null, pin: "", double_encrypt: false, emergency_code: "", read_receipt: false, send_reminder: false, note: "", });
	useEffect(() => {
		let total = 2.0;
		if (formData.double_encrypt) total += 5.0;
		if (formData.read_receipt) total += 2.0;
		if (formData.send_reminder) total += 2.0;
		if (showNote) total += 1.0;
		if (usePin) total += 1.0;
		if (useEmergencyUnlock) total += 2.0;
		if (formData.openstamp) {
			const openDate = dayjs(formData.openstamp);
			const currentDate = dayjs();
			const diff = openDate.diff(currentDate, "year");
			total += diff * 0.25 - 0.25;
		}
		if (files.length > 0) {
			total += 1 * files.length;
		}
		if(total < 2.0) total = 2.0;
		setPrice(total);
	}, [formData, useEmergencyUnlock, showNote, usePin, files]);
	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: type === "checkbox" ? checked : value,
		}));
	};
	const handleDateChange = (date) => {
		setFormData((prevState) => ({
			...prevState,
			openstamp: date ? date.toISOString() : null, // Ensure this is a string
		}));
	};
	const handleFileChange = (event) => {
		const newFiles = Array.from(event.target.files);
		setFiles((prevFiles) => [...prevFiles, ...newFiles]);
	};
	const removeFile = (index) => {
		setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};
	const handlePaymentResult = async (result) => {
		if (result.success) {
			// payment good
			const formDataWithFiles = new FormData();
			Object.keys(formData).forEach((key) => {
				formDataWithFiles.append(key, formData[key] || ""); // Append empty string if value is null or undefined
			});
			// Append files to the form data
			files.forEach((file) => {
				formDataWithFiles.append("files", file);
			});
			formDataWithFiles.append("preCapsuleId", preCapsuleId);
			const response = await fetch( "https://momentstash.com/api/testing", { method: "POST", body: formDataWithFiles, } );
			if (response.ok) {
				const result = await response.json();
				const data = { capsule_id: result.message };
        navigate('/creating-capsule', { state: { data } });
			} else {
				const errorResult = await response.json();
				setError(errorResult.message);
			}
		} else {
			// payment failed
      setError(result.message);
		}
	};
  const startPayment = async () => {
    try {
			setError("");
			const intentresponse = await fetch(
				"https://momentstash.com/api/create-intent",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ amount: price }),
				}
			);
			const intentjson = await intentresponse.json();
			if (intentjson.error) {
				setError(intentjson.message);
				return;
			}
			setClientSecret(intentjson.message.client_secret);
			setPreCapsuleId(intentjson.message.capsule_id);
			setModalOpen(true);
			return;
		} catch (error) {
			setError("An error occurred while creating the capsule.");
		}
  };
	const handleSubmit = async (e) => {
		e.preventDefault();
    const formDataWithFiles = new FormData();
			Object.keys(formData).forEach((key) => {
				formDataWithFiles.append(key, formData[key] || ""); // Append empty string if value is null or undefined
			});
			// Append files to the form data
			files.forEach((file) => {
				formDataWithFiles.append("files", file);
			});
			formDataWithFiles.append("preCapsuleId", preCapsuleId);
		try {
      setError("");
      const response = await fetch( "https://momentstash.com/api/pre-check", { method: "POST", body: formDataWithFiles, } );
      const result = await response.json();
      if(result.error){
        setError(result.message);
        return;
      } else {
        startPayment();
      }
    } catch (error) {
      setError("An error occurred while creating the capsule.");
    }
	};
	return (
		<div className="flex flex-col min-h-screen text-white">
			<HeaderComponent />
			<main className="flex-grow py-6">
				<Container maxWidth="sm">
					<Card className="shadow-lg rounded-lg bg-white bg-opacity-80">
					<CardHeader title="Create A Time Capsule" titleTypographyProps={{ variant: "h5", align: "center", className: "font-bold" }} className="bg-gradient-to-r from-teal-500 to-blue-500 text-white" />
						<CardContent>
							<form onSubmit={handleSubmit}>
								<h2 className="text-lg font-bold mb-4">Your Information</h2>
								<TextField label="Name" name="from_name" fullWidth sx={{ mb: 2 }} onChange={handleChange} />
								<TextField label="Email" name="from_email" fullWidth sx={{ mb: 2 }} onChange={handleChange} />
								<div className="flex justify-between items-center">
									<h2 className="text-lg mb-4">Send To Someone</h2>
									<Switch
										name="sendToSomeone"
										checked={sendToSomeone}
										onChange={(e) => {
											setSendToSomeone(e.target.checked);
											if (!e.target.checked) {
												setFormData((prevData) => ({
													...prevData,
													to_name: '',
													to_email: '',
												}));
											}
										}}
									/>
									</div>
									{sendToSomeone && (
										<>
											<TextField
												label="Their Name"
												name="to_name"
												fullWidth
												sx={{ mb: 2 }}
												value={formData.to_name}
												onChange={handleChange}
											/>
											<TextField
												label="Their Email"
												name="to_email"
												fullWidth
												sx={{ mb: 2 }}
												value={formData.to_email}
												onChange={handleChange}
											/>
										</>
									)}
								<h2 className="text-lg font-bold mb-4">Contents</h2>
								<TextField label="Message" name="message" fullWidth multiline rows={4} sx={{ mb: 2 }} value={formData.message} onChange={handleChange} />
								<div className="p-0">
									<p className="text-lg font-bold mb-2">Files: - $1/Each</p>
									<p className="text-sm text-gray-500 mb-2">
										You can upload multiple files.
									</p>
									<ul className="list-disc pl-5 space-y-2">
										{files.map((file, index) => (
											<li
												key={index}
												className="flex items-center justify-between bg-gray-100 p-2 rounded shadow-sm">
												<span className="text-sm text-gray-700">
													{file.name}
												</span>
												<button type="button" onClick={() => removeFile(index)} className="text-red-500 hover:text-red-700 focus:outline-none">
													x
												</button>
											</li>
										))}
									</ul>
									<input type="file" multiple onChange={handleFileChange} className="mt-4 border border-gray-300 p-2 rounded mb-4" />
								</div>
								<h2 className="text-lg font-bold mb-4">Capsule Time</h2>
								<p>1 Year Free then $0.25 / Year</p>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["DateTimePicker"]}>
										<DateTimePicker label="Select Open Date & Time" name="openstamp" value={ formData.openstamp ? dayjs(formData.openstamp) : null } onChange={handleDateChange} />
									</DemoContainer>
								</LocalizationProvider>
								<h2 className="text-lg font-bold mb-4 mt-4">Security</h2>
								<div className="flex justify-between items-center">
									<h2 className="text-md mb-4">Use Password/PIN - $1</h2>
									<Switch
										name="usePin"
										checked={usePin}
										onChange={(e) => {
											setUsePin(e.target.checked);
											if (!e.target.checked) {
												setFormData((prevData) => ({ ...prevData, pin: '' }));
											}
										}}
									/>
								</div>
								{usePin && (
									<>
										<p className="text-sm mb-4">
											Set a password or PIN that is required to open the
											capsule.
										</p>
										<TextField
											label="Password/PIN"
											name="pin"
											fullWidth
											sx={{ mb: 2 }}
											value={formData.pin}
											onChange={handleChange}
										/>
									</>
								)}
								<div className="flex justify-between items-center">
									<h2 className="text-md mb-4">Double Encrypt - $5</h2>
									<Switch
										name="double_encrypt"
										checked={formData.double_encrypt}
										onChange={handleChange}
									/>
								</div>
								<div className="flex justify-between items-center">
									<h2 className="text-md mb-4">Emergency Unlock - $2</h2>
									<Switch
										name="useEmergencyUnlock"
										checked={useEmergencyUnlock}
										onChange={(e) => {
											setUseEmergencyUnlock(e.target.checked);
											if (!e.target.checked) {
												setFormData((prevData) => ({ ...prevData, emergency_code: '' }));
											}
										}}
									/>
									</div>
									{useEmergencyUnlock && (
										<>
											<p className="text-sm mb-4">
												Set a password or PIN that is required to open the capsule.
											</p>
											<TextField
												label="Emergency Password/PIN"
												name="emergency_code"
												fullWidth
												sx={{ mb: 2 }}
												value={formData.emergency_code}
												onChange={handleChange}
											/>
										</>
									)}

								<h2 className="text-lg font-bold mb-4">Extras</h2>
								<div className="flex justify-between items-center">
									<h2 className="text-md mb-4">Read Receipt - $2</h2>
									<Switch
										name="read_receipt"
										checked={formData.read_receipt}
										onChange={handleChange}
									/>
								</div>
								<div className="flex justify-between items-center">
									<h2 className="text-md mb-4">Reminder - $2</h2>
									<Switch
										name="send_reminder"
										checked={formData.send_reminder}
										onChange={handleChange}
									/>
								</div>
								<div className="flex justify-between items-center">
									<h2 className="text-md mb-4">Add Note - $1</h2>
									<Switch
										name="showNote"
										checked={showNote}
										onChange={(e) => {
											setShowNote(e.target.checked);
											if (!e.target.checked) {
												setFormData((prevData) => ({ ...prevData, note: '' }));
											}
										}}
									/>
									</div>
									{showNote && (
										<>
											<p className="text-sm mb-4">
												Add a note that will be included with the capsule.
											</p>
											<TextField
												label="Note"
												name="note"
												fullWidth
												sx={{ mb: 2 }}
												value={formData.note}
												onChange={handleChange}
											/>
										</>
									)}

								<Divider sx={{ my: 2 }} />
								<div className="flex justify-between items-center">
									<h2 className="text-md mb-4">Total:</h2>
									<p className="text-md mb-4">${price.toFixed(2)}</p>
								</div>
								<Divider sx={{ my: 2 }} />
								{error && <p className="text-red-500">{error}</p>}
								<Button
									type="submit"
									variant="contained"
									color="primary"
									sx={{ mt: 2, width: "100%", paddingY: 2 }}>
									Create Capsule
								</Button>
							</form>
						</CardContent>
					</Card>
				</Container>
			</main>
			<PaymentModal
				open={modalOpen}
				onClose={handleClose}
				clientSecret={clientSecret}
				price={price}
				onPaymentComplete={handlePaymentResult}
				setPreCapsuleId={setPreCapsuleId}
			/>
			<FooterComponent />
		</div>
	);
};
export default CreateCapsulePage;
