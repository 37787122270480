import React, { useEffect, useRef, useState, useCallback } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import { Container, Card, CardHeader, CardContent } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';

const CreatingCapsule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;
  const [animate, setAnimate] = useState(false);
  const imgRef1 = useRef(null);
  const imgRef2 = useRef(null);
  const containerRef = useRef(null);
  const [centerOffset, setCenterOffset] = useState(0);

  const calculateTransform = useCallback(() => {
    if (containerRef.current && imgRef1.current && imgRef2.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const imageWidth = imgRef1.current.offsetWidth;
      const offset = (containerWidth / 2) - (imageWidth / 2);
      setCenterOffset(offset);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setAnimate(true), 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    calculateTransform();
    window.addEventListener('resize', calculateTransform);
    return () => window.removeEventListener('resize', calculateTransform);
  }, [calculateTransform]);

  useEffect(() => {
    const handleTransitionEnd = () => {
      const timer = setTimeout(() => {
        if (data) {
          const cdata = { capsule_id: data.capsule_id };
          navigate('/capsule-created', { state: { cdata } });
        }
      }, 1000);
      return () => clearTimeout(timer);
    };

    if (imgRef1.current && imgRef2.current) {
      imgRef1.current.addEventListener('transitionend', handleTransitionEnd);
      imgRef2.current.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (imgRef1.current && imgRef2.current) {
        imgRef1.current.removeEventListener('transitionend', handleTransitionEnd);
        imgRef2.current.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [animate, data, navigate]);

  return (
    <div className="flex flex-col min-h-screen text-white">
      <HeaderComponent />
      <main className="flex-grow py-6">
        <Container maxWidth="sm">
          <Card className="shadow-lg rounded-lg bg-white bg-opacity-80">
            <CardHeader
              title="Creating Capsule..."
              titleTypographyProps={{ variant: "h5" }}
              className="bg-gradient-to-r from-teal-500 to-blue-500 text-white"
            />
            <CardContent>
              <div className="text-center">Generating Capsule: {data?.capsule_id}</div>
              <div
                ref={containerRef}
                className="relative w-full h-48 overflow-hidden flex items-center justify-center"
              >
                <img
                  ref={imgRef1}
                  src="/caps/l.webp"
                  alt="Image 1"
                  style={{
                    transform: animate ? `translateX(${centerOffset}px)` : 'translateX(-100%)',
                  }}
                  className="absolute w-24 h-auto left-0 transition-transform duration-5000 ease-linear"
                />
                <img
                  ref={imgRef2}
                  src="/caps/r.webp"
                  alt="Image 2"
                  style={{
                    transform: animate ? `translateX(-${centerOffset}px)` : 'translateX(100%)',
                  }}
                  className="absolute w-24 right-0 h-auto transition-transform duration-5000 ease-linear"
                />
              </div>
            </CardContent>
          </Card>
        </Container>
      </main>
    </div>
  );
};

export default CreatingCapsule;
