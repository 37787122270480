import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., sending data to the backend)
    console.log('Form submitted:', formData);
    setFormSubmitted(true); // Set form submission state to true
  };

  return (
    <>
      <HeaderComponent />
      <Container maxWidth="sm" className="py-12">
        <Box className="bg-white p-8 shadow-lg rounded-lg">
          {formSubmitted ? (
            <Typography variant="h5" className="text-center text-green-600">
              Thank you! Your message has been sent.
            </Typography>
          ) : (
            <>
              <Typography variant="h4" gutterBottom className="font-bold">
                Contact Us
              </Typography>
              <form onSubmit={handleSubmit} className="space-y-6">
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  variant="outlined"
                  className="mt-4"
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="outlined"
                  className="mt-4"
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  variant="outlined"
                  multiline
                  rows={4}
                  className="mt-4"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="mt-6 bg-blue-500"
                >
                  Send Message
                </Button>
              </form>
            </>
          )}
        </Box>
      </Container>
      <FooterComponent />
    </>
  );
};

export default ContactPage;
