import React from "react";

const formatDate = (date) => {
  const d = new Date(date);
  return d.toLocaleString();
};

const CapsuleViewComponent = ({ capsuleData }) => {
  const files = capsuleData.files || []; 
  return (
    <div>
      <p className="mb-4 text-center"><strong>Capsule ID</strong><br />{capsuleData.capsule_id}</p>
      <p className="mb-4 text-center"><strong>Created At</strong><br />{formatDate(capsuleData.created_at)}</p>
      {capsuleData.note && <p className="mb-4 text-center"><strong>Note:</strong><br />{capsuleData.note}</p>}
      <p className="mb-4 text-center"><strong>Message:</strong><br />{capsuleData.message}</p>
      {files.length > 0 && (
        <p className="mb-4 text-center"><strong>Files:</strong><br />
        <ol>
          {capsuleData.files.map((fileObj, index) => (
            <li key={index}>
              <a href={`https://r2.momentstash.com/${fileObj.file}`} download={fileObj.file} target="_blank" rel="noopener noreferrer">
                {index + 1}) File {fileObj.file}
              </a>
            </li>
          ))}
        </ol>
        </p>
        )}
    </div>
  );
};

export default CapsuleViewComponent;

