import React from 'react';
import { Container, Typography, Card, CardContent } from '@mui/material';
import 'tailwindcss/tailwind.css';
import HeaderComponent from '../components/HeaderComponent';
import HomeHero from '../components/HomeHero';
import HeroReasons from '../components/HeroReasons';
import HomeCall from '../components/HomeCall';

const TestPage = () => {
  return (
    <>
    <HeaderComponent />
    <main className="flex-grow py-6">
    <HomeHero />
    <HeroReasons />
    <HomeCall />
    </main>
    </>
  );
};

export default TestPage;
