import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';

const TOSPage = () => {
  return (
    <>
      <HeaderComponent />
    <Container maxWidth="md" className="py-12">
      <Box className="bg-white p-8 shadow-lg rounded-lg">
        <Typography variant="h4" gutterBottom className="font-bold">
          Terms of Service
        </Typography>
        <Typography variant="body1" className="my-4">
          Welcome to Moment Stash. These Terms of Service ("Terms") govern your use of our website and services. By accessing or using Moment Stash, you agree to be bound by these Terms.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          1. User Content
        </Typography>
        <Typography variant="body1" className="my-4">
          You retain ownership of the content you create or upload to Moment Stash. By using our services, you grant us a license to store, display, and distribute your content as necessary to operate the service.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          2. Prohibited Activities
        </Typography>
        <Typography variant="body1" className="my-4">
          You agree not to use Moment Stash for any unlawful or prohibited activities, including but not limited to the creation or distribution of illegal content, harassment, or violation of any laws.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          3. Payments and Refunds
        </Typography>
        <Typography variant="body1" className="my-4">
          All payments made through Moment Stash are non-refundable, except as required by law. We reserve the right to change our pricing at any time.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          4. Termination
        </Typography>
        <Typography variant="body1" className="my-4">
          We may suspend or terminate your account or access to our services at any time, with or without cause, and without notice. Upon termination, your right to use our services will immediately cease.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          5. Disclaimer of Warranties
        </Typography>
        <Typography variant="body1" className="my-4">
          Moment Stash is provided "as is" and "as available" without warranties of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          6. Limitation of Liability
        </Typography>
        <Typography variant="body1" className="my-4">
          In no event shall Moment Stash or its affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, arising out of or related to your use of our services.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          7. Governing Law
        </Typography>
        <Typography variant="body1" className="my-4">
          These Terms are governed by and construed in accordance with the laws of the jurisdiction in which Moment Stash operates, without regard to its conflict of law principles.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          8. Changes to These Terms
        </Typography>
        <Typography variant="body1" className="my-4">
          We reserve the right to modify these Terms at any time. If we make changes, we will notify you by revising the date at the top of these Terms and, in some cases, we may provide additional notice.
        </Typography>

        <Typography variant="h6" className="font-semibold mt-6">
          9. Contact Us
        </Typography>
        <Typography variant="body1" className="my-4">
          If you have any questions about these Terms, please contact us.
        </Typography>
      </Box>
    </Container>

    <FooterComponent />
    </>
  );
};

export default TOSPage;
