import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import {Button} from "@mui/material";
const CheckoutComponent = ({price, onPaymentComplete, open, setPreCapsuleId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault(); 
    setLoading(true);
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        //return_url: `${window.location.origin}/subscription-complete`,
      },
      redirect: 'if_required'
    });
    if (error) {
      // Pass the error message directly
      setError(error.message);
      onPaymentComplete({ success: false, message: error.message });
      open = false;
    } else if (paymentIntent.status === 'succeeded') {
      onPaymentComplete({ success: true, paymentIntent });
      open = false;
    }
    setLoading(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <h1 className="flex justify-center font-bold text-2xl mb-4" >Checkout</h1>
      <h1 className="flex justify-center text-lg mb-4" >${price.toFixed(2)}</h1>
      <PaymentElement />
      {error && <div>{error}</div>}
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, width: '100%', paddingY: 2 }}  disabled={loading || !stripe || !elements}>
        {loading ? 'Loading...' : 'Submit Payment'}
      </Button>
    </form>
  );
}
export default CheckoutComponent;