import { Link } from "react-router-dom";
const FooterComponent = () => {

  return (
    <footer className="footer bg-msgold text-center p-2 text-sm text-white">
      <p>
        <Link to="/privacy" className="text-white">Privacy Policy</Link> | <Link to="/tos" className="text-white">Terms of Service</Link>
      </p>
      <span className="text-muted">&copy; Moment Stash 2024 - All Rights Reserved</span>
    </footer>
  );
}

export default FooterComponent;