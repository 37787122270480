import React, { useState, useEffect } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutComponent from "../components/CheckoutComponent";
import { Modal, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const PaymentModal = ({ clientSecret, open, onClose, price, onPaymentComplete }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const theme = useTheme();
  const appearance = { theme: 'stripe', labels: 'floating' };
  const options = { clientSecret: clientSecret, appearance: appearance };

  useEffect(() => {
    setStripePromise(loadStripe('pk_live_51PBkjy2MsisEK4FeW2PHp9B53EmHxMKyJPZ4ChMsAvuKbUHNlXyOdUwvO6W2HgQEiBh2FdpLRz7z3ayLdDuJZs9x00AY6KuIO2'));
  }, []);

  return (
    <div style={{ overflowY: 'auto' }}>
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="payment-modal-title"
      aria-describedby="payment-modal-description"
      className="flex items-center justify-center"
    >
      <Box
        sx={{
          width: 500,
          maxHeight: '80vh', // Set max height to 80% of the viewport height
          bgcolor: 'background.paper',
          p: 3,
          borderRadius: 2,
          boxShadow: 24,
          overflowY: 'auto', // Enable vertical scrolling
          ...theme.typography.body2,
        }}
        className="p-6 mx-4 my-6 max-w-lg rounded-lg bg-white"
      >
        <Elements stripe={stripePromise} options={options}>
          <CheckoutComponent price={price} onPaymentComplete={onPaymentComplete} open={open} />
        </Elements>
      </Box>
    </Modal>
    </div>
  );
};

export default PaymentModal;

